<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    <h4 style="font-weight:500">Support</h4>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-card>
        <v-container>
            <v-row>
                <v-col justify="center" align="center">
                    <img style="max-width:100%; max-height:auto;" src="../assets/docs.png" alt=""/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>